
import {defineComponent, ref, watch} from 'vue'
import ActivityService from "@/core/services/ActivityService";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";

export default defineComponent({
  name: "CustomerUpdate",
  components: {DateTimeFormat, Avatar},
  props: {
    customerUpdateId: {type: String},
  },
  setup(props) {
    const data = ref<any>({});
    const load = (id) => {
      if (id) {
        ActivityService.get(id).then(res => {
          data.value = res;
        });
      } else {
        data.value = {};
      }
    }
    load(props.customerUpdateId);
    watch(()=>props.customerUpdateId, cb=>{
      load(cb);
    })
    return {
      data,
    }
  }
})
