
import {computed, defineComponent, onRenderTracked, onUpdated} from 'vue'
import store from "@/store";
import CustomerStatus from "@/views/customer/CustomerStatus.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import EntityViewAvatar from "@/components/base/common/EntityViewAvatar.vue";
import CustomerUpdate from "@/views/claim/CustomerUpdate.vue";

export default defineComponent({
  name: "Customer",
  components: {CustomerUpdate, EntityViewAvatar, DateTimeFormat, CustomerStatus},
  setup() {
    onUpdated(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    onRenderTracked(() => {
      const link = document?.getElementById("main-overview")
      if (link) {
        link.classList.remove("active")
      }
    })
    const claim = computed(() => store.state.ClaimModule.claim);
    return {
      claim,
    }
  }
})
